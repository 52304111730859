// course slice labels
export const lectureDetails='lectureDetails';
export const courseSliceName='course';
export const undefinedType='undefined';

// course and cart
export const cartItems="cartItems";
export const coursesSync="coursessync";
export const allCoursesLabel="allcourses";

// quiz labels
export const testEmail="testEmail";
export const quizDemoPassword='TestUser@123';

// react course labels
export const subjectId="subjectid";
export const subjectName="subject";
export const subjectVersionLabel="subjectVersion";
export const react18Version=18;
export const reactId='60c7106e1527710009ff2ee4';

// player labels
export const startedTime="startedTime";
export const videoPlayer='videoplayer';
export const playerView='playerview';

// course modes
export const CourseModes={
  playerMode:'playerMode',
  learning:'learning',
  practice:'practice'
}

// player types
export const PlayerTypes={
  vmvideoplayer:'vmvideoplayer'
}

// popup
export const PopupKeys={
  showPopup:"showPopup",
  showPopup3:"showPopup4",
}

// auth user
export const AuthUser={
  token:"token",
  username:"username",
  email:"email",
  id:"id"
}