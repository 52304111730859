import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import PurchasePopup from "../../common/PurchasePopup";
import VMModal from "../../common/VMModal";
import { AuthToken } from "../../utilities";
import { openPlayer } from "../VMVideoPlayer/useCourses";
import {TimerCountDown} from "./FlashNewsPopup";
import SelectBatch from "./SelectBatch";

export default function PurchaseOption({
  to = "",
  enrollto = "",
  isPurchase = false,
  isEnroll = false,
  amount = 0,
  original = 0,
  enrollamount = 0,
  enrolloriginal = 0,
  isEnrolled = false,
  course={},
  selectedBatch,
  setSelectedBatch,
  showTimer=false
}) {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const isLoggedIn = AuthToken !== null;
  useEffect(
    () => {
      window.scrollTo(0, 0);
      let mobile = /iphone|ipod|android|blackberry|mini|windows\sce|palm/i.test(navigator.userAgent.toLowerCase());
      if (mobile) {
        setShow(true);
      } else {
        setShow(false);
      }
    },
    [show],
  );
  return (
    <div>
      {isEnroll ? (
        <div>
          {/* <a href="https://www.youtube.com/watch?v=FK3yeRkwiwU" target="_blank"
            style={{ cursor: "pointer" }}
            rel="noreferrer"
            className="nav-link text-center font-weight-bold shadow bg-dark text-white p-4 m-2 rounded">
            <i className="fa fa-play-circle" /> <b className="text-primary">CLICK HERE</b> FOR <b>DEMO SESSION</b> (New Batch - August 13th)
          </a> */}
          <SelectBatch selectedBatch={selectedBatch} setSelectedBatch={setSelectedBatch} />
          <Link
            to={enrollto+"?batch="+selectedBatch}
            style={{ cursor: "pointer" }}
            className="nav-link text-center font-weight-bold shadow bg-dark p-4 m-2 rounded text-white enroll-link">
            <i className="fa fa-laptop" /> {show ? "ENROLL NOW " : "ENROLL NOW "}
            (<b className="text-yellow">Rs.
            {parseInt(enrollamount).toLocaleString('en-IN')}
            /-</b>){" "}
            <sub>
              <del>{parseInt(enrolloriginal).toLocaleString('en-IN')}</del>
            </sub>
          </Link>
          {/* <div className="timer-container">
          {showTimer && <TimerCountDown/>}
          </div> */}
        </div>
      ) : (
        <React.Fragment>
          <br />
          <br />
          <br />
        </React.Fragment>
      )}

      {isPurchase &&
        <div
          onClick={() => {
            if(isLoggedIn && isEnrolled){
              openPlayer(course, history)
            } else{
            history.push(to);
            }
          }}
          style={{ cursor: "pointer" }}
          className="nav-link text-center font-weight-bold shadow bg-white m-2 rounded text-primary p-3"
        >
          {isLoggedIn && isEnrolled ? <><i className="fa fa-play-circle" /> LEARN NOW</>:
          <>
          <i className="fa fa-shopping-cart" /> BUY NOW (Rs.{parseInt(amount).toLocaleString('en-IN')}/-)
          <sub>
            <del>{parseInt(original).toLocaleString('en-IN')}</del>
          </sub>
          </>}
        </div>}

      {showPayment && <VMModal proceed={false} size="lg" title="Payment and Confirm Order" component={<PurchasePopup course={to.split("/purchase/")[1]} onClose={setShowPayment} />} onClose={setShowPayment} />}
    </div>
  );
}
