import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import './VMCelebrations.css'
import { useHistory } from 'react-router-dom';

export default function VMCelebrations() {
  const [remainingTime, setRemainingTime] = React.useState(5);
    const timerRef=React.useRef(0);
    const history=useHistory();

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setRemainingTime((prevTime)=>prevTime-1);
    }, 1000);

    const timeout=setTimeout(() => {
      history.replace('/vm-landing-page');
    }, 5000);

    const audio = new Audio('/audio/firework-1-29803.mp3');
    setTimeout(() => {
      audio.play();
    }, 1000);

    return ()=>{
      clearTimeout(timeout);
      clearInterval(timerRef.current);
    }
  }, [])
  return (
    <div className='diwali-celebration'>
      <h1 className='heading-diwali-celebration popup-animation'> 🥳<span className='heading-diwali'>Happy సంక్రాంతి Everyone</span>🤗</h1>
      <p>Wish you all a very Happy Sankranti and a prosperous New Year!</p>
      <img src="/images/vm-character.png" alt="vm-character" className='vm-character-right popup-animation' />
      <img src="/images/vm-character.png" alt="vm-character" className='vm-character-left' />
      <div className="btn-wrapper">
      <Link to="/vm-landing-page"><button className='continue-btn'>{remainingTime}</button></Link>
      </div>
    </div>
  )
}
