import React from 'react';
import moment from 'moment';
import InstallApp from '../components/InstallApp';

export default function Festival() {
  const festivals = [
    {
      date: "01-01",
      festival: "HAPPY NEW YEAR TO YOU AND YOUR FAMILY MEMBERS"
    },
    {
      date: "01-02",
      festival: "HAPPY NEW YEAR TO YOU AND YOUR FAMILY MEMBERS"
    },
    {
      date: "01-03",
      festival: "HAPPY NEW YEAR TO YOU AND YOUR FAMILY MEMBERS"
    },
    {
      date: "01-12",
      festival: "SRI SWAMI VIVEKANANDA JAYANTI"
    },
    {
      date: "01-13",
      festival: "HAPPY BHOGI TO YOU AND YOUR FAMILY MEMBERS"
    },
    {
      date: "01-14",
      festival: "HAPPY MAKARA SANKRANTI TO YOU AND YOUR FAMILY MEMBERS"
    },
    {
      date: "01-16",
      festival: "HAPPY KANUMA TO YOU AND YOUR FAMILY MEMBERS"
    },
    {
      date: "01-23",
      festival: "SRI NETAJI SUBHAS CHANDRA BOSE JAYANTI"
    },
    {
      date: "01-26",
      festival: "HAPPY REPUBLIC DAY"
    },
    {
      date: "02-08",
      festival: "రథ సప్తమి శుభాకాంక్షలు"
    },
    {
      date: "02-14",
      festival: "HAPPY VALENTINES DAY"
    },
    {
      date: "02-19",
      festival: "SRI CHATRAPATI SHIVAJI MAHARAJ JAYANTI"
    },
    {
      date: "02-26",
      festival: "HAPPY MAHASHIVARATRI TO YOU AND YOUR FAMILY MEMBERS"
    },
    {
      date: "03-14",
      festival: "HAPPY HOLI TO YOU AND YOUR FAMILY MEMBERS"
    },
    {
      date: "03-30",
      festival: "HAPPY UGADI TO YOU AND YOUR FAMILY MEMBERS"
    },
    {
      date: "03-31",
      festival: "HAPPY RAMZAN TO YOU AND YOUR FAMILY MEMBERS"
    },
    {
      date: "04-05",
      festival: "SRI BABU JAGJIVAN RAM JAYANTI"
    },
    {
      date: "04-06",
      festival: "HAPPY SRI RAMA NAVAMI TO YOU AND YOUR FAMILY MEMBERS"
    },
    {
      date: "04-14",
      festival: "SRI Dr. B.R.AMBEDKAR JAYANTI"
    },
    {
      date: "04-18",
      festival: "HAPPY GOOD FRIDAY TO YOU AND YOUR FAMILY MEMBERS"
    },
    {
      date: "05-01",
      festival: "HAPPY MAY DAY"
    },
    {
      date: "05-09",
      festival: "SRI GURU RABINDRANATH TAGORE JAYANTI"
    },
    {
      date: "05-16",
      festival: "HAPPY BUDDHA PURNIMA"
    },
    {
      date: "06-07",
      festival: "HAPPY BAKRID TO YOU AND YOUR FAMILY MEMBERS"
    },
    {
      date: "07-06",
      festival: "HAPPY MOHARRUM TO YOU AND YOUR FAMILY MEMBERS"
    },
    {
      date: "08-09",
      festival: "HAPPY RAKSHA BANDHAN"
    },
    {
      date: "08-15",
      festival: "HAPPY INDEPENDENCE DAY"
    },
    {
      date: "08-27",
      festival: "HAPPY GANESH CHATURTHI TO YOU AND YOUR FAMILY MEMBERS"
    },
    {
      date: "09-05",
      festival: "HAPPY TEACHERS DAY TO ALL THE INSPIRING TEACHERS!"
    },
    {
      date: "09-30",
      festival: "HAPPY VIJAYA DASHAMI TO YOU AND YOUR FAMILY MEMBERS"
    },
    {
      date: "10-02",
      festival: "HAPPY GANDHI JAYANTI"
    },
    {
      date: "10-31",
      festival: "HAPPY DIWALI TO YOU AND YOUR FAMILY MEMBERS"
    },

    {
      date: "10-20",
      festival: "HAPPY DIWALI TO YOU AND YOUR FAMILY MEMBERS"
    },
    {
      date: "12-25",
      festival: "HAPPY CHRISTMAS TO YOU AND YOUR FAMILY MEMBERS"
    }
  ]
  return (
    <>
      {festivals.filter((fest) => fest.date.indexOf(moment(new Date()).format("MM-DD")) > -1).length > 0 ?
        <p
          style={{ fontSize: 25, borderBottomLeftRadius: 100, borderBottomRightRadius: 100 }}
          className="text-center custom-bg text-white align-items-center d-flex justify-content-center flex-wrap p-3 shadowAnim">
          🎉🎉{festivals.filter((fest) => fest.date.indexOf(moment(new Date()).format("MM-DD")) > -1).map(({ festival },index) => (
            <span key={index}>{festival} 🎉🎉</span>
          ))}
        </p> :
        //<p
        //  style={{ fontSize: 25, borderBottomLeftRadius: 100, borderBottomRightRadius: 100 }}
        //  className="text-center custom-bg text-white align-items-center d-flex justify-content-center flex-wrap p-3 shadowAnim">
        //  <span className='text-uppercase'>👩‍💻 Learn - Practice - Develop - Repeat 🧑‍💻</span>
        //</p>
        <InstallApp />
      }
    </>

  )
}
