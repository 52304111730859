import React from 'react'

export default function FAQs() {
  const faqs = [
    {
      question: "What is the duration of the course?",
      answer: "Regular Batch - 3 weeks. Weekend Batch - 3 weeks."
    },
    {
      question: "What are the prerequisites for the course?",
      answer: "HTML, CSS, JavaScript Basics such as Variables, Functions, Objects, Arrays, Conditionals, Loops. Basics are enough, in-depth knowledge is not required."
    },
    {
      question: "Where can we attend Live Classes and Q&A Sessions?",
      answer: "Google Meet Link and WhatsApp Group Links Will Be Provided."
    },
    {
      question: "What other features can we access?",
      answer: "Recorded Lectures, Source Code, Quizzes, Practice Tasks, Presentations Material, Certification, Community Support, Life-time access for all the content."
    },
    {
      question: "How can I enroll in the course?",
      answer: "Step-1: Make Payment Step-2: Sign up with your email and full name. Step-3: Once payment and sign up is done, account credentials will be shared within 2 minutes. That's all, You can start attending the classes from the specified date and time."
    },
  ];
  return (
    <div className="mt-4 mb-4 bg-light border p-2 rounded">
      <h3 className="text-warning"><i className="fa fa-question-circle" /> Frequently Asked Questions:</h3>

      <div className="row justify-content-center align-items-center">
        <div>
          {faqs.map((faq, index) => (
            <FAQ key={index} index={index + 1} faq={faq} />
          ))}
        </div>
      </div>
    </div>
  )
}

const FAQ = ({ faq, index }) => {
  const [isActive, setIsActive] = React.useState(false);
  const handleShowAnswer = () => {
    setIsActive(!isActive);
  }
  return (
    <div className='m-3 faq-question '>
      <h4 className={`d-flex justify-content-between align-items-center cursor-pointer ${isActive ? 'faq-answer' : ''}`} onClick={handleShowAnswer}>
        {index}.{faq.question}
        {isActive ? <i className='fa fa-angle-down' /> : <i className='fa fa-angle-right' />}
      </h4>
      {isActive ? <p className='faq-answer'>{faq.answer}</p> : null}
    </div>
  )
}