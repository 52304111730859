import React, { useState, useEffect, useRef } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { discountDuration, regularBatchText, Validator } from "../utilities";
import PleaseWait from "./PleaseWait";
import SelectBatch from "../components/courses/SelectBatch";

const surpriseDiscount = localStorage.getItem("surpriseDiscount");
const discount = surpriseDiscount ? surpriseDiscount : 0;

export default function Enroll() {
  const [show, setShow] = useState(false);
  const [fullname, setfullname] = useState("");
  const [email, setemail] = useState("");
  const [message, setMessage] = useState("");
  const [courseName, setCourseName] = useState("");
  const [whatsappType, setWhatsappType] = useState("");
  const [loading, setLoading] = useState(false);
  const [referralCode, setReferralCode] = useState("VMDEMO2024");
  const [timeLeft, setTimeLeft] = useState(0); // initialize timeLeft
  const [, forceUpdate] = useState(false);
  const validator = Validator();
  const { course } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const batch = searchParams.get("batch");
  const [selectedBatch, setSelectedBatch] = useState(batch || regularBatchText);
  const whatsappLinkRef = useRef();
  const whatsappLinkRef2 = useRef();

  // Initialize or calculate time left for the discount
  const initializeTimer = () => {
    const storedStartTime = localStorage.getItem('discountStartTime');
    const now = Math.floor(Date.now() / 1000);

    if (storedStartTime) {
      const elapsed = now - parseInt(storedStartTime, 10);
      if (elapsed < discountDuration) {
        setTimeLeft(discountDuration - elapsed);
      } else {
        localStorage.removeItem('discountStartTime'); // Expired, clear storage
        setTimeLeft(0);
      }
    }
  };

  useEffect(() => {
    initializeTimer();
  }, []);

  useEffect(() => {
    if (timeLeft <= 0) return;

    const timer = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
  };

  useEffect(
    () => {
      window.scrollTo(0, 0);
      let mobile = /iphone|ipod|android|blackberry|mini|windows\sce|palm/i.test(navigator.userAgent.toLowerCase());
      if (mobile) {
        setShow(true);
      } else {
        setShow(false);
      }
      if (course === "reactredux") {
        setCourseName("React and Redux Online Course");
      } else if (course === "javascript") {
        setCourseName("JavaScript Online Course");
      } else if (course === "nodejs") {
        setCourseName("NodeJS Online Course");
      } else if (course === "mernstack") {
        setCourseName("MERN Stack Online Course");
      } else if (course === "meanstack") {
        setCourseName("MEAN Stack Online Course");
      } else if (course === "jsandmernstack") {
        setCourseName("JavaScript and MERN Stack Online Course");
      } else if (course === "jsandreact") {
        setCourseName("JavaScript and React Online Course");
      } else if (course === "jsandnodejs") {
        setCourseName("JavaScript and NodeJS Online Course");
      } else if (course === "mernstackproject") {
        setCourseName("MERN Stack Project");
      } else if (course === "html5") {
        setCourseName("HTML5 Complete Course");
      } else if (course === "css3") {
        setCourseName("CSS3 Complete Course");
      } else if (course === "webdeveloper") {
        setCourseName("Web Developer Full Course");
      } else if (course === "react-2024") {
        setCourseName("React Online Training");
      } else if (course === "react-summer-2024") {
        setCourseName("React Summer Internship And Training");
      } else if (course === "frontend-2024") {
        setCourseName("Frontend Online Training");
      }
    },
    [show],
  );
  const submitLogin = (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      setLoading(true);
      if (show) {
        whatsappLinkRef.current.click();
        setLoading(false);
      } else {
        whatsappLinkRef2.current.click();
        setLoading(false);
      }
    } else {
      validator.current.showMessages();
      forceUpdate(true);
    }
  };
  const isDiscountTimeOut = discount > 0 && timeLeft > 0;
  const existingUsers = referralCode === "EXCLUSIVE2024";
  const vmDemoCode = referralCode === "VMDEMO2024";
  const discountPrice = vmDemoCode ? 4499 : (existingUsers ? 2999 : (4999 - (isDiscountTimeOut ? discount : 0)));
  const paymentLink = `${existingUsers ? "https://pmny.in/PAYUMN/MJ150IWwzHOP" : ((isDiscountTimeOut || vmDemoCode) ? "https://pmny.in/PAYUMN/9IXlZp9fTcXw" : "https://pmny.in/PAYUMN/eJ9A79RMErC3")}`;
  // 2999 link - https://pmny.in/PAYUMN/MJ150IWwzHOP
  // 4499 link - https://pmny.in/PAYUMN/9IXlZp9fTcXw
  // 4999 link - https://pmny.in/PAYUMN/eJ9A79RMErC3
  return (
    <div className="pt-4 pb-4 custom-bg">
      {/* <DiscountPopup /> */}
      {isDiscountTimeOut && <div className="bg-dark container text-white p-2 rounded">
        <p className="text-center m-0"> ️‍🔥Special Discount Ends In: <b>{formatTime(timeLeft)}️‍🔥</b>
        </p>
      </div>}
      <div className="mt-4 mb-4 p-2 rounded container bg-dark">
        <div className="bg-white p-3 rounded">
          <h3 className="text-center">
            Enroll for <b className="text-primary"> <Link to={"/" + course}>{courseName}</Link>
              - Rs.{discountPrice.toLocaleString()}
              <small className="text-warning"><sub><del>Rs.20,000</del></sub></small>
            </b>
            (<span className="text-primary">{existingUsers ? 85 : (vmDemoCode?80:(isDiscountTimeOut) ? 80 : 80)}% Discount Applied</span>)
          </h3>
          <SelectBatch selectedBatch={selectedBatch} setSelectedBatch={setSelectedBatch} />
        </div>
        {whatsappType === "order" &&
          show && (
            <a
              className="text-success"
              href={
                "https://api.whatsapp.com/send?phone=+918328664336&text=Hello Venkatesh! My name is " +
                fullname +
                ", my email address is " +
                email +
                ". I have enrolled to " +
                courseName +
                "[ " + selectedBatch + " ]. Please let me know the status of the order."
              }
              target="_blank"
              id="link1"
              hidden
              ref={whatsappLinkRef}>
              <i className="fa fa-whatsapp text-success" /> <b>Click here</b>
            </a>
          )}
        {whatsappType === "order" &&
          !show && (
            <a
              className="text-success"
              href={
                "https://api.whatsapp.com/send?phone=+918328664336&text=Hello Venkatesh!, My name is " +
                fullname +
                ", my email address is " +
                email +
                ". I have enrolled to " +
                courseName +
                "[ " + selectedBatch + " ]. Please let me know the status of the order."
              }
              target="_blank"
              id="link2"
              hidden
              ref={whatsappLinkRef2}>
              <i className="fa fa-whatsapp text-success" /> <b>Click here</b>
            </a>
          )}
        {whatsappType === "query" &&
          show && (
            <a
              className="text-success"
              href={
                "https://api.whatsapp.com/send?phone=+918328664336&text=Hello Venkatesh!, My name is " +
                fullname +
                ", my email address is " +
                email +
                ". I have a query regarding " +
                courseName +
                "[ " + selectedBatch + " ]."
              }
              target="_blank"
              id="link1"
              hidden
              ref={whatsappLinkRef}>
              <i className="fa fa-whatsapp text-success" /> <b>Click here</b>
            </a>
          )}
        {whatsappType === "query" &&
          !show && (
            <a
              className="text-success"
              href={
                "https://api.whatsapp.com/send?phone=+918328664336&text=Hello Venkatesh!, My name is " +
                fullname +
                ", my email address is " +
                email +
                ". I have a query regarding " +
                courseName +
                "[ " + selectedBatch + " ]."
              }
              target="_blank"
              id="link2"
              hidden
              ref={whatsappLinkRef2}>
              <i className="fa fa-whatsapp text-success" /> <b>Click here</b>
            </a>
          )}
        <PaymentFlow show={show} submitLogin={submitLogin} fullname={fullname} setfullname={setfullname} setMessage={setMessage}
        validator={validator} email={email} setemail={setemail} referralCode={referralCode} setReferralCode={setReferralCode} message={message} loading={loading} setWhatsappType={setWhatsappType}/>
      </div>
    </div>
  );
}

export const PaymentFlow=({show,submitLogin,fullname,setfullname,setMessage,validator,email,setemail,referralCode,setReferralCode,message,loading,setWhatsappType})=>{

  return (
    <div>
      <div className="row d-flex justify-content-center p-0 m-0 popup-animation">

      <div className="col-md-4 col-xs-12 shadow border text-white p-2 m-lg-3 mt-3 text-center">
            <h3 className="text-center custom-bg p-2 rounded">Step-1: Make Payment</h3>
            <img src="/images/donate/phonepe.png" className="w-100 rounded" alt="payment" />
            <p className="text-dark text-center text-white">
              Please share screenshot once payment is done and follow the Step-2 👨‍💻
            </p>
          </div>

          <div className="col-md-1 d-flex align-items-center justify-content-center">
          <i className={`fa fa-angle-double-${show?'down':'right'} popup-animation-infinite`} style={{ fontSize: 200, color: "orange" }} />
          </div>
      <div className="col-md-4 col-xs-12 shadow border text-white p-2 m-lg-3 mt-3 text-center">
            <form onSubmit={submitLogin}>

            <h3 className="text-center custom-bg p-2 rounded">Step-2: Sign Up!</h3>
              <i className="fa fa-user-plus popup-animation" style={{ fontSize: 200, color: "orange" }} />
              <input
                type="text"
                placeholder="Enter your full name"
                className="form-control"
                value={fullname}
                onChange={(e) => setfullname(e.target.value)}
                onFocus={() => setMessage("")}
              />
              {validator.current.message("Full Name ", fullname, "required")}
              <br />
              <input
                type="email"
                placeholder="Enter your email address"
                className="form-control"
                value={email}
                onChange={(e) => setemail(e.target.value)}
                onFocus={() => setMessage("")}
              />
              {validator.current.message("Email Address ", email, "required|email")}
              <br />
              <label htmlFor="referal">Discount Promo Code</label>
              <input
                type="text"
                placeholder="Enter your referral code"
                className="form-control"
                value={referralCode}
                onChange={(e) => setReferralCode(e.target.value)}
                onFocus={() => setMessage("")}
              // disabled
              />
              <span className="error-message">{message}</span>
              <br />
              <input type="submit" value="Sign Up" className="btn btn-primary w-50" hidden id="submitBtn" />
              <div className="d-flex">
                {!loading && (
                  <input
                    type="button"
                    onClick={() => {
                      setWhatsappType("order");
                      setTimeout(() => {
                        document.getElementById("submitBtn").click();
                      }, 100);
                    }}
                    value="Sign Up"
                    className="btn btn-primary w-100"
                  />
                )}
                &nbsp;
                {/* {!loading && (
                  <input
                    type="button"
                    onClick={() => {
                      setWhatsappType("query");

                      setTimeout(() => {
                        document.getElementById("submitBtn").click();
                      }, 100);
                    }}
                    value="Have queries"
                    className="btn btn-outline-warning w-50"
                  />
                )} */}
              </div>
              {loading && <PleaseWait content={"Please wait..."} />}
              <p className="text-dark text-center text-white mt-5">
              Fill your details and sign up! That's all, your enrollment will be done in 2 minutes!🥳
            </p>
            </form>
          </div>
        </div>

        <hr className="bg-secondary" />
        <h3 className="text-center text-white">Other Payment Options</h3>
        <hr className="bg-secondary" />
        <div className="row d-flex justify-content-center p-0 m-0">
          <div className="col-md-4 col-xs-12 shadow text-white p-2 m-lg-3 mt-3 bg-white">
          <h3 className="text-center text-dark font-weight-bold">Google Pay</h3>
            <img src="/images/donate/gpay.jpg" className="w-100 rounded" alt="payment" />
            <br />
            <br />
            <br />
          </div>
          <div className="col-md-4 col-xs-12 shadow bg-white p-2 m-lg-3 mt-3">
            <img src="/images/payment.png" className="w-100 rounded" alt="payment" />
            <br />
            <p className="text-dark text-center">
              Please share screenshot once payment is done and fill your details and sign up!
            </p>
            {/* <hr className="bg-secondary" />
            <h5 className="text-center">OR</h5>
            <hr className="bg-secondary" />
            <p className="text-center text-secondary">
              <a href={paymentLink} target="_blank" className="text-decoration-none btn btn-primary"><i className="fa fa-rupee" /> Make Online Payment (Outside India)</a>
            </p>

            <p className="text-dark text-center">
              Please share screenshot once payment is done and fill your details and sign up!
            </p> */}
          </div>
        </div>
        <div className="bg-white p-3 rounded">
          <h3 className="text-center">Contact us through whatsapp <a href="https://web.whatsapp.com/send?phone=+918328664336&text=Hello Venkatesh! I'm interested to enroll to React course. I have few queries." target="_blank" className="text-decoration-none">8328664336</a> If you have any queries.</h3>
        </div>
    </div>
  )
}