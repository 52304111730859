import React from 'react'
import { Link } from 'react-router-dom'
import './LandingPage.css'

export default function LandingPage() {
  const videoRef = React.useRef(null);
  return (
    <div className='landing-page-container'>
      <video autoPlay muted loop className="landing-video-bg" ref={videoRef}>
        <source src="/videos/video-bg.mp4" type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>

      <div className="landing-container">
        <h1>Welcome to VM Online Training Academy</h1>
        <p>Expand your skills with our curated online courses.</p>
        <Link to="/courses"><button className="landing-explore-btn">Explore Courses</button></Link>
      </div>
    </div>
  )
}
