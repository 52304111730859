import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { allCoursesStatic, allCoursesStored, api, baseURL, Validator } from "../utilities";
import PleaseWait from "./PleaseWait";
import { removeFromStorage } from "../utilities/localStorage";
import { cartItems } from "../redux/constants/courseConstants";
import { PaymentFlow } from "./Enroll";

export default function Purchase() {
  const [show, setShow] = useState(false);
  const [fullname, setfullname] = useState("");
  const [email, setemail] = useState("");
  const [message, setMessage] = useState("");
  const [courseName, setCourseName] = useState("");
  const [whatsappType, setWhatsappType] = useState("");
  const [referralCode, setReferralCode] = useState("VM25");
  const [refereename, setRefereename] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [originalPrice, setOriginalPrice] = useState(0);
  const [courselink, setCourseLink] = useState("");
  const [, forceUpdate] = useState(false);
  const validator = Validator();
  const { course } = useParams();
  const whatsappLinkRef = useRef();
  const whatsappLinkRef2 = useRef();
  const history = useHistory();

  useEffect(
    () => {
      window.scrollTo(0, 0);
      let mobile = /iphone|ipod|android|blackberry|mini|windows\sce|palm/i.test(navigator.userAgent.toLowerCase());
      if (mobile) {
        setShow(true);
      } else {
        setShow(false);
      }
      let foundCourse = allCoursesStored.filter(item => item.name === course);
      if (foundCourse.length > 0) {
        setTotalPrice(foundCourse[0].price - ((foundCourse[0].price / 100) * foundCourse[0].discount));
        setDiscount(foundCourse[0].discount);
        setOriginalPrice(foundCourse[0].price);
      }
      let foundCourse2 = allCoursesStatic.filter(item => item.courselink === course);
      if (foundCourse2.length > 0) {
        setTotalPrice(foundCourse2[0].price - ((foundCourse2[0].price / 100) * foundCourse2[0].discount));
        setDiscount(foundCourse2[0].discount);
        setOriginalPrice(foundCourse2[0].price);
        setCourseLink(foundCourse2[0].courselink);
      }
      if (course === "react-2024") {
        history.replace("/enroll/react-2024",);
      } else if (course === "frontend-2024") {
        history.replace("/enroll/frontend-2024",);
      } else if (course === "react-summer-2024") {
        history.replace("/enroll/react-summer-2024",);
      }
      if (course === "reactredux" || course === "React with Redux") {
        setCourseName("React with Redux Online Course");
      } else if (course === "javascript" || course === "JavaScript") {
        setCourseName("JavaScript Online Course");
      } else if (course === "nodejs" || course === "NodeJS") {
        setCourseName("NodeJS Online Course");
      } else if (course === "mernstack" || course === "MERN Stack") {
        setCourseName("MERN Stack Online Course");
      } else if (course === "meanstack" || course === "MEAN Stack") {
        setCourseName("MEAN Stack Online Course");
      } else if (course === "jsandmernstack" || course === "JS and MERN Stack") {
        setCourseName("JavaScript and MERN Stack Online Course");
      } else if (course === "jsandreact" || course === "JS and React") {
        setCourseName("JavaScript and React Online Course");
      } else if (course === "jsandreactjs" || course === "JS and React") {
        setCourseName("JavaScript and React Online Course");
      } else if (course === "jsandnodejs" || course === "JS and NodeJS") {
        setCourseName("JavaScript and NodeJS Online Course");
      } else if (course === "mernstackproject" || course === "MERN Stack Project") {
        setCourseName("MERN Stack Project");
        setCourseLink("projects");
      } else if (course === "html5" || course === "HTML") {
        setCourseName("HTML5 Complete Course");
      } else if (course === "css3" || course === "CSS") {
        setCourseName("CSS3 Complete Course");
      } else if (course === "webdeveloper" || course === "Web Development Course") {
        setCourseName("Web Developer Full Course");
      } else if (course === "reactnative" || course === "React Native") {
        setCourseName("React Native Complete Course");
      } else if (course === "javascriptsmart" || course === "JavaScript Smart Course") {
        setCourseName("JavaScript Smart Course");
      } else if (course === "reactandreactnative" || course === "React and React Native") {
        setCourseName("React and React Native Course");
      } else if (course === "angular" || course === "Angular") {
        setCourseName("Angular Complete Course");
      } else if (course === "reacttypescript" || course === "React TypeScript Complete Course") {
        setCourseName("React TypeScript Complete Course");
      } else if (course === "react-2024" || course === "React Online Training") {
        setCourseName("React Online Training");
      } else if (course === "react-summer-2024" || course === "React Summer Internship and Training") {
        setCourseName("React Summer Internship and Training");
      } else if (course === "frontend-2024" || course === "Frontend Online Training") {
        setCourseName("Frontend Online Training");
      } else if (course === "allcourses") {
        setCourseName("All Current Courses");
        setTotalPrice(9999)
        setDiscount(60)
        setOriginalPrice(25900);
      } else if (course === "cart") {
        let cart = localStorage.getItem("cartItems") != null ? JSON.parse(localStorage.getItem("cartItems")) : [];
        let totalAmount = 0;
        let finalDiscount = 0;
        cart.sort((a, b) => b.price - a.price).forEach((item, index) => {
          if (cart.length >= 10) {
            finalDiscount = 60;
          }
          // else if (cart.length > 3) {
          //   finalDiscount = 50;
          // }  else if (cart.length > 2) {
          //   finalDiscount = 40;
          // } else if (cart.length > 1) {
          //   finalDiscount = 30;
          // }
          else {
            finalDiscount = Number(item.discount);
          }
          let finalval = parseInt(item.price) - ((parseInt(item.price) / 100) * parseInt(finalDiscount));
          totalAmount += (cart.length % 2 === 0 || cart.length > 1) && index % 2 === 0 ? Number(finalval) : (cart.length === 1 ? Number(finalval) : 0);
        })
        setTotalPrice(totalAmount)
        let courses = cart.map((item, index) => " " + (index + 1) + ") " + item.title + " - Rs." + (parseInt(item.price) - ((parseInt(item.price) / 100) * parseInt(item.discount))) + "/-");
        let originalPrice = cart.reduce((total, item) => total + parseInt(item.price), 0);
        let coursesString = courses.toString();
        setCourseName("Selected Courses : \n" + coursesString + ",\n Original Price: Rs." + originalPrice + "/-,\n Final Price : Rs." + totalAmount + "/-");
        const finalDiscountNow = Math.ceil(100 - (Number(totalAmount) / cart.reduce((prev, current) => Number(prev) + Number(current.price), 0)) * 100);
        setDiscount(finalDiscountNow);
        setReferralCode("VM" + finalDiscountNow);
        setOriginalPrice(originalPrice)
      }
    },
    [show],
  );
  const submitLogin = (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      setLoading(true);
      let data = {
        email,
        fullname,
        discount: discount,
        courseName: courseName,
        courseDiscountPrice: totalPrice,
        courseOriginalPrice: originalPrice,
        queryType: whatsappType,
        status: "unread"
      };
      api(baseURL + "/createConfirmOrder", "POST", { "content-type": "application/json" }, data).then((res) => {
        if (res.success) {
          setLoading(false);
          if (show) {
            whatsappLinkRef.current.click();
            setLoading(false);
          } else {
            whatsappLinkRef2.current.click();
            setLoading(false);
          }
        }
      }).catch((err) => {
        setLoading(false);
      })
    } else {
      validator.current.showMessages();
      forceUpdate(true);
    }
    setTimeout(() => {
      removeFromStorage(cartItems);
    }, 30000);
  };
  return (
    <div className="p-2 container-fluid custom-bg">
      <div className="mt-4 mb-4 p-2 rounded container bg-dark">
        <div className="p-3 bg-white rounded">
          <h4 className="text-center">
            <Link to={"/" + (courseName.includes("Selected Courses") ? "cartitems" : courselink)} className="text-decoration-none text-dark"><i className="fa fa-laptop text-warning" />  {" "}
              <b className="text-warning">{courseName.includes("Selected Courses") ? "Your Selected Courses" : courseName} {totalPrice > 0 ? `- Rs.${totalPrice.toLocaleString("en-IN")}/-` : ''} <sub className="text-secondary">(<del>Rs.{originalPrice.toLocaleString('en-IN')}/-</del>)</sub><br /><span className="text-success">({discount < 30 ? "🎉 1+1 Offer Applied: Get a free course with this enrollment! 🎉" : discount + "% Discount Applied"}) </span></b></Link>
          </h4>
          {courseName === "All Current Courses" && <div className="rounded bg-white text-dark shadow p-1 m-1 text-center">
            <p>HTML, CSS, JAVASCRIPT, JavaScript Smart, React, NodeJS, React Native, MERN Stack Project, Angular, React TypeScript Courses.</p>
          </div>}
        </div>
        {whatsappType === "order" &&
          show && (
            <a
              className="text-success"
              href={
                "https://api.whatsapp.com/send?phone=+918328664336&text=Hello Venkatesh!, My name is " +
                fullname +
                ", my email address is " +
                email +
                ". I have purchased " +
                courseName +
                " for Rs." + totalPrice + "/- with " + discount + "% discount." +
                " My Referral Code: " + referralCode + discount +
                //, My Referee Name is: " + (refereename.length > 0 ? refereename : "VM Training") +
                " Please let me know the status of the order."
              }
              target="_blank"
              id="link1"
              hidden
              ref={whatsappLinkRef}>
              <i className="fa fa-whatsapp text-success" /> <b>Click here</b>
            </a>
          )}
        {whatsappType === "order" &&
          !show && (
            <a
              className="text-success"
              href={
                "https://api.whatsapp.com/send?phone=+918328664336&text=Hello Venkatesh!, My name is " +
                fullname +
                ", my email address is " +
                email +
                ". I have purchased " +
                courseName +
                " for Rs." + totalPrice.toLocaleString("INR") + "/- with " + discount + "% discount." +
                " My Referral Code: " + referralCode + discount +
                //, My Referee Name is: " + (refereename.length > 0 ? refereename : "VM Training") +
                " Please let me know the status of the order."
              }
              target="_blank"
              id="link2"
              hidden
              ref={whatsappLinkRef2}>
              <i className="fa fa-whatsapp text-success" /> <b>Click here</b>
            </a>
          )}
        {whatsappType === "query" &&
          show && (
            <a
              className="text-success"
              href={
                "https://api.whatsapp.com/send?phone=+918328664336&text=Hello Venkatesh!, My name is " +
                fullname +
                ", my email address is " +
                email +
                ". I have a query regarding " +
                courseName +
                "."
              }
              target="_blank"
              id="link1"
              hidden
              ref={whatsappLinkRef}>
              <i className="fa fa-whatsapp text-success" /> <b>Click here</b>
            </a>
          )}
        {whatsappType === "query" &&
          !show && (
            <a
              className="text-success"
              href={
                "https://api.whatsapp.com/send?phone=+918328664336&text=Hello Venkatesh!, My name is " +
                fullname +
                ", my email address is " +
                email +
                ". I have a query regarding " +
                courseName +
                "."
              }
              target="_blank"
              id="link2"
              hidden
              ref={whatsappLinkRef2}>
              <i className="fa fa-whatsapp text-success" /> <b>Click here</b>
            </a>
          )}

        <PaymentFlow show={show} submitLogin={submitLogin} fullname={fullname} setfullname={setfullname} setMessage={setMessage}
          validator={validator} email={email} setemail={setemail} referralCode={referralCode} setReferralCode={setReferralCode} message={message} loading={loading} setWhatsappType={setWhatsappType} />
      </div>
    </div>
  );
}
