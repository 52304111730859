import { regularBatchText } from "../../utilities";

export default function SelectBatch({ selectedBatch, setSelectedBatch }) {
  return (
    <div>
      <select className='form-select p-3' value={selectedBatch} onChange={(e) => setSelectedBatch(e.target.value)}>
          <option value={regularBatchText}>😎{regularBatchText}</option>
          {/* <option value="Evening Batch -- 9 PM - 10 PM (M,W,F)" disabled>🌙Evening Batch -- 8 PM - 9 PM (M,W,F) - Not Available</option> */}
      </select>

      <a href='https://web.whatsapp.com/send?phone=+918328664336&text=Hello%20Venkatesh!%20I%27m%20interested%20to%20enroll/purchase%20your%20courses.%20I%20have%20few%20queries.' className='btn btn-primary p-3 mt-3 w-100' rel="noopener noreferrer" target="_blank" >📞Contact Admin for Weekend Batch -- 9 AM - 12 PM (S,S)</a>
    </div>
  )
}
