import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { api } from "../utilities/api";
import { baseURL } from "../utilities/baseurl";
import { AuthEmail, AuthToken, logoutUser } from "../utilities/validations";
import { eveningBatchUsers, internUsers, recordedUsers, regularBatchUsers } from "../components/auth/Login";
import { useSelector } from "react-redux";

export default function Navbar() {
  const [feedback, setFeedback] = useState(false);
  const [loading, setLoading] = useState(false);
  const { cartCount = 0 } = useSelector(store => store.course);
  useEffect(() => {
    if (AuthToken != null) {
      api(
        baseURL + "/getfeedbacks",
        "POST",
        { "content-type": "application/json", Authorization: AuthToken },
        { email: AuthEmail },
      )
        .then((data) => {
          if (data.success) {
            setFeedback(true);
          } else {
            setFeedback(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [])
  const isLiveUser = (recordedUsers.includes(AuthEmail) || regularBatchUsers.includes(AuthEmail) || eveningBatchUsers.includes(AuthEmail));
  const liveDashboardLink = recordedUsers.includes(AuthEmail) ? '/vmlive/react-2024/recorded' : (regularBatchUsers.includes(AuthEmail) ? '/vmlive/react-2024-regular' : (eveningBatchUsers.includes(AuthEmail) ? '/vmlive/react-2024-evening' : '/vm/vmcourses'))
  const isInternUser = internUsers.includes(AuthEmail);
  return (
    <nav className="navbar navbar-expand-lg custom-bg2 navbar-dark bg-dark sticky-top">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand">
          <h2>
            <b style={{ color: "orange" }}><i className="fa fa-laptop text-warning" /> VM</b> Online Training
            <sub className="d-block">4.0</sub>
          </h2>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 justify-content-lg-center align-items-lg-center">
            <NavLink to="/courses" className="nav-link" activeClassName="text-white active-course">
              <li className="nav-item">Courses</li>
            </NavLink>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                Trainings <span className="badge bg-primary badge-pill">New</span>
              </a>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                <NavLink to="/react-2024" className="dropdown-item">
                  React Online Training<span className="badge bg-primary badge-pill">New</span>
                </NavLink>
                {/* <NavLink to="/frontend-2024" className="dropdown-item">
                  Frontend Online Training<span className="badge bg-primary badge-pill">New</span>
                </NavLink> */}
              </div>
            </li>
            <NavLink to="/html5" className="nav-link" activeClassName="text-white active-course">
              <li className="nav-item">
                HTML
              </li>
            </NavLink>
            <NavLink to="/css3" className="nav-link" activeClassName="text-white active-course">
              <li className="nav-item">
                CSS
              </li>
            </NavLink>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                JavaScript
              </a>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                {/* <NavLink to="/frontend-2024" className="dropdown-item">
                  Frontend Online Training<span className="badge bg-primary badge-pill">New</span>
                </NavLink> */}
                <NavLink to="/javascript" className="dropdown-item">
                  JavaScript <span className="badge bg-primary badge-pill">New</span>
                </NavLink>
                <NavLink to="/angular" className="dropdown-item">
                  Angular <span className="badge bg-primary badge-pill">New</span>
                </NavLink>
                <NavLink className="dropdown-item" to="/jsandmernstack">
                  JS and MERN Stack
                </NavLink>
                <NavLink className="dropdown-item" to="/jsandreactjs">
                  JS and ReactJS
                </NavLink>
                <NavLink className="dropdown-item" to="/jsandnodejs">
                  JS and NodeJS
                </NavLink>
                <NavLink className="dropdown-item" to="/javascriptsmart">
                  JS Smart Course
                </NavLink>
                <NavLink to="/webdeveloper" className="dropdown-item">
                  Web Development
                </NavLink>
                <NavLink to="/meanstack" className="dropdown-item">
                  MEAN Stack Course
                </NavLink>
              </div>
            </li>

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                React <span className="badge bg-primary badge-pill">New</span>
              </a>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                <NavLink to="/react-2024" className="dropdown-item">
                  React Online Training<span className="badge bg-primary badge-pill">New</span>
                </NavLink>
                {/* <NavLink to="/react-summer-2024" className="dropdown-item">
                  React Summer Internship And Training<span className="badge bg-primary badge-pill">New</span>
                </NavLink> */}
                <NavLink to="/reactredux" className="dropdown-item">
                  <span className="nav-item">React and Redux</span> <span className="badge bg-primary badge-pill">New</span>
                </NavLink>
                <NavLink to="/reacttypescript" className="dropdown-item">
                  React TypeScript <span className="badge bg-primary badge-pill">New</span>
                </NavLink>
                <NavLink className="dropdown-item" to="/reactnative">
                  React Native
                </NavLink>
                <NavLink className="dropdown-item" to="/reactandreactnative">
                  React and React Native
                </NavLink>
                <NavLink className="dropdown-item" to="/mernstack">
                  MERN Stack Course
                </NavLink>
                <NavLink className="dropdown-item" to="/projects">
                  MERN Stack Project
                </NavLink>
              </div>
            </li>

            <NavLink to="/nodejs" className="nav-link" activeClassName="text-white active-course">
              <li className="nav-item">
                NodeJS
              </li>
            </NavLink>
            {/* <NavLink to="/html5" className="nav-link" activeClassName="text-white active-course">
              <li className="nav-item">
                Blog <span className="badge bg-warning badge-pill">New</span>
              </li>
            </NavLink> */}
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                More
              </a>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                <NavLink to="/react-2024" className="dropdown-item">
                  React Online Training<span className="badge bg-primary badge-pill">New</span>
                </NavLink>
                {/* <NavLink to="/react-summer-2024" className="dropdown-item">
                  React Summer Internship And Training<span className="badge bg-primary badge-pill">New</span>
                </NavLink> */}
                {/* <NavLink to="/frontend-2024" className="dropdown-item">
                  Frontend Online Training<span className="badge bg-primary badge-pill">New</span>
                </NavLink> */}
                <NavLink className="dropdown-item" to="/meanstack">
                  MEAN Stack Course
                </NavLink>
                <NavLink to="/webdeveloper" className="dropdown-item">
                  Web Development
                </NavLink>
                <NavLink className="dropdown-item" to="/mernstack">
                  MERN Stack Course
                </NavLink>
                <NavLink to="/allcourses" className="dropdown-item">
                  All Courses Discount
                </NavLink>
                <NavLink to="/vm/vmcourses" className="dropdown-item">
                  <li className="nav-item animcolor font-weight-bold">VM Learning</li>
                </NavLink>
                <NavLink to="/onlinetests" className="dropdown-item">
                  Online Tests
                </NavLink>
                {/*<NavLink to="/jobs" className="dropdown-item">
                  Jobs  <span className="badge bg-dark badge-pill">New</span>
                </NavLink>*/}
                {/*<NavLink to="/fullstack" className="dropdown-item">
                  Full Stack Developer <span className="badge bg-warning badge-pill">New</span>
                </NavLink>*/}
                {/*<NavLink className="dropdown-item" to="/reactnative">
                  React Native <span className="badge bg-warning badge-pill">Coming Soon!</span>
                </NavLink>
                <NavLink className="dropdown-item" to="/angular">
                  Angular <span className="badge bg-warning badge-pill">Coming Soon!</span>
                </NavLink>*/}
              </div>
            </li>
            {/* <img src="/images/newimage.gif" className="new-img" alt="new image" /> */}
          </ul>
          <form className="d-flex flex-wrap justify-content-between">
            <Link to="/cartitems" className="position-relative">
              {cartCount > 0 && <div className="badge bg-secondary cartCircle">{cartCount}</div>}
              <button className="btn btn-sm btn-outline-primary me-2 navOption" title="Cart">
                {" "}
                <i className="fa fa-cart-plus" />
              </button>
            </Link>
            {/* <Link to="/verify">
              <button className="btn btn-outline-primary btn-sm me-2 navOption" title="Verify Certificate">
                {" "}
                <i className="fa fa-certificate" />
              </button>
            </Link> */}
            <Link to="/whatsapp">
              <button className="btn btn-outline-success btn-sm me-2 navOption" type="button" title="WhatsApp">
                {" "}
                <i className="fa fa-whatsapp" />
              </button>
            </Link>
            {/* <a
              href="mailto:mogilivenkatesh3@gmail.com?subject=VM Online Training&amp;body=
							Hello Venkatesh! I'm interested to enroll/purchase your course. I have few queries. Thank you!
            "
              className="link"
              style={{ textDecoration: "none" }}>
              <button className="btn btn-outline-secondary btn-sm me-2 navOption" type="button" title="Gmail">
                <i className="fa fa-google" />
              </button>
            </a> */}

            {/* <a
              href="https://www.youtube.com/c/VenkateshMogili"
              target="_blank"
              rel="noreferrer"
              className="link me-2 navOption"
              style={{ textDecoration: "none" }}>
              <button className="btn btn-outline-danger btn-sm" type="button" title="YouTube">
                <i className="fa fa-youtube-play" />
              </button>
            </a> */}

            {/* <Link to="/about-team">
              <button className="btn btn-outline-info btn-sm me-2 navOption" title="About">
                {" "}
                <i className="fa fa-users" />
              </button>
            </Link> */}
            {AuthToken !== null && (
              <li className="list-style-none nav-item dropdown user-dropdown1" style={{ listStyleType: "none" }}>
                <button
                  className="btn btn-outline-primary btn-sm ml-0 me-2 dropdown-toggle user-dropdown1"
                  id="navbarDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <i className="fa fa-user" /> {localStorage.getItem("username")}
                </button>
                <div className="dropdown-menu dropdown-menu-left dropdown-menu-end user-dropdown user-dropdown1" aria-labelledby="navbarDropdown">
                  {isInternUser && <NavLink to={"/talent"} className="dropdown-item">
                    💻 Talent Dashboard
                  </NavLink>}
                  {isLiveUser && <NavLink to={liveDashboardLink} className="dropdown-item">
                    🔴Live Training Dashboard
                  </NavLink>}
                  <NavLink to={'/vm/vmcourses'} className="dropdown-item">
                    Dashboard
                  </NavLink>
                  {/*<Link to="/notifications" className="dropdown-item">Notifications</Link>*/}
                  <NavLink to="/changepassword" className="dropdown-item">
                    Change Password
                  </NavLink>
                  {!feedback && <NavLink to="/feedback" className="dropdown-item">
                    Feedback
                  </NavLink>}
                  <NavLink to="/deleteaccount" className="dropdown-item">
                    Delete Account
                  </NavLink>
                  {/* <a className="dropdown-item" href="/#">
                  Edit Profile
                </a> */}
                  {/* <div className="dropdown-divider" /> */}
                  <hr />
                  <button
                    type="button"
                    className="dropdown-item cursor-pointer bg-danger text-white"
                    onClick={logoutUser}>
                    <i className="fa fa-power-off" /> Logout
                  </button>
                </div>
              </li>
            )}
            {AuthToken === null && (
              <Link to="/login">
                <button className="btn btn-outline-warning btn-sm me-2 navOption">
                  {" "}
                  Login <i className="fa fa-user" title="Login" />
                </button>
              </Link>
            )}
          </form>
        </div>
      </div>
    </nav>
  );
}
