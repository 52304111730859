import React, { Suspense, lazy } from "react";
import "./App.css";
import { Switch, Redirect, useLocation } from "react-router-dom";
import PublicRoute from "./common/PublicRoute";
import Courses from "./components/courses/Courses";
import VMCourses from "./components/VMVideoPlayer/Courses";
import VMVideoPlayer from "./components/VMVideoPlayer/VMVideoPlayer";
import About from "./common/About";
import Whatsapp from "./common/Whatsapp";
import Verify from "./components/auth/Verify";
import Login from "./components/auth/Login";
import Navbar from "./common/Navbar";
import NavbarVM from "./common/NavbarVM";
import PrivateRoute from "./common/PrivateRoute";
import ChangePassword from "./components/auth/ChangePassword";
import ForgetPassword from "./components/auth/ForgetPassword";
import DeleteAccount from "./components/auth/DeleteAccount";
import ResetPassword from "./components/auth/ResetPassword";
import withClearCache from "./ClearCache";
import Feedback from "./components/auth/Feedback";
import Purchase from "./common/Purchase";
import Projects from "./components/courses/Projects";
import Enroll from "./common/Enroll";
import JobsContainer from "./components/courses/Jobs/JobsContainer";
import Notifications from "./components/Notifications";
import OnlineTestContainer from "./components/OnlineTest";
import OnlineTests from "./components/courses/OnlineTests";
import VMCoins from "./components/vmcoins";
import CourseDetails from "./components/courses/CourseDetails";
import CartItems from "./components/cart/CartItems";
import PrivacyPolicy from "./common/PrivacyPolicy";
import ScrollToTop from "react-scroll-to-top";
import Pricing from "./components/Pricing";
import LearningRoutes from "./components/Learning/LearningRoutes";
import AboutUs from "./common/AboutUs";
import TermsAndConditions from "./common/TermsAndConditions";
import RefundPolicy from "./common/RefundPolicy";
import ContactUS from "./common/ContactUs";
import NotFound from "./common/NotFound";
import ActivateCTVSignIn from "./components/auth/ActivateCTVSignIn";
import VMLiveTraining from "./components/VMLiveTraining";
import VMCelebrations from "./common/VMCelebrations";
import TutorsRoutes from "./components/TutorsDashboard/TutorsRoutes";
import TalentRoutes from "./components/TalentDashboard/TalentRoutes";
import LandingPage from "./common/LandingPage";
const AllCourses = lazy(() => import("./components/courses/AllCourses"));
const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp() {
  let { pathname } = useLocation();
  let redirectPath="/vm-celebrations";
  const currentDate=new Date();
  const isToday = currentDate.getDate() === 12 && currentDate.getMonth() === 0;
  if(isToday){
    redirectPath="/vm-celebrations";
  } else{
    redirectPath="/vm-landing-page";
  }
  return (
    <div>
      <Suspense fallback={<h1>Loading...</h1>}>
      {pathname.split("/")[1] === "talent" && <TalentRoutes/>}
      {pathname.split("/")[1] === "tutors" && <TutorsRoutes/>}
      {pathname.split("/")[1] === "learning" && <LearningRoutes/>}
      {pathname.split("/")[1] !== "learning" && pathname.split("/")[1]!=="tutors" && pathname.split("/")[1]!=="talent" &&
          <div>
            {pathname.split("/")[1] === "vm" ? <NavbarVM /> : <Navbar />}
            <Switch>
              <Redirect from="/" to={redirectPath} exact />
              <PublicRoute path="/cartitems" component={CartItems} exact />
              <PublicRoute path="/courses" component={Courses} exact />
              <PublicRoute path="/allcourses" component={AllCourses} exact />
              <PublicRoute path="/about" component={AboutUs} exact />
              <PublicRoute path="/terms-and-conditions" component={TermsAndConditions} exact />
              <PublicRoute path="/refund-policy" component={RefundPolicy} exact />
              <PublicRoute path="/contact" component={ContactUS} exact />
              <PublicRoute path="/privacy-policy" component={PrivacyPolicy} exact />
              <PublicRoute path="/about-team" component={About} exact />
              <PublicRoute path="/whatsapp" component={Whatsapp} exact />
              <PublicRoute path="/verify" component={Verify} exact />
              <PublicRoute path="/login" component={Login} exact />
              <PublicRoute path="/activate" component={ActivateCTVSignIn} exact />
              <PublicRoute path="/jobs" component={JobsContainer} exact />
              <PublicRoute path="/onlinetests" component={OnlineTests} exact />
              <PublicRoute
                path="/onlinetest/:quizName/:quizType"
                component={OnlineTestContainer}
                exact
              />
              <PublicRoute path="/projects" component={Projects} exact />
              <PublicRoute path="/purchase/:course" component={Purchase} exact />
              <PublicRoute path="/enroll/:course" component={Enroll} exact />
              <PublicRoute
                path="/forgotpassword"
                component={ForgetPassword}
                exact
              />
              <PublicRoute
                path="/resetpassword/:token"
                component={ResetPassword}
                exact
              />
              <PublicRoute
                path="/deleteaccount"
                component={DeleteAccount}
                exact
              />
              <PrivateRoute
                path="/changepassword"
                component={ChangePassword}
                exact
              />
              <PrivateRoute path="/vmcoins" component={VMCoins} exact />
              <PrivateRoute path="/feedback" component={Feedback} exact />
              <PrivateRoute path="/notifications" component={Notifications} exact />
              {/* vmvideoplayer 2.0 */}
              <PublicRoute path="/vm/vmcourses" component={VMCourses} exact />
              <PublicRoute path="/pricing" component={Pricing} exact />
              <PublicRoute
                path="/vm/vmvideoplayer"
                component={VMVideoPlayer}
                exact
              />
              <PublicRoute
                path="/vmlive/react-2024/:courseType"
                component={VMLiveTraining}
                exact
              />
              <PublicRoute
                path="/vmlive/react-2024-regular"
                component={VMLiveTraining}
                exact
              />
              <PublicRoute
                path="/vmlive/react-2024-evening"
                component={VMLiveTraining}
                exact
              />
              <PublicRoute
                path="/vm-celebrations"
                component={VMCelebrations}
                exact
              />
              <PublicRoute
                path="/vm-landing-page"
                component={LandingPage}
                exact
              />
              {/*<PublicRoute path="/vm/vmvideoplayer" component={VMVideoPlayer} exact />*/}
              <PublicRoute path="/:course" component={CourseDetails} exact />
              <PublicRoute path="*" component={NotFound} exact/>
            </Switch>
          </div>}
      </Suspense>
      <ScrollToTop smooth color="#FFA500" />
    </div>
  );
}

export default App;
